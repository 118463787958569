@counter-style side-notes {
    system: cyclic;
    symbols: "👈" "📆";
    suffix: "   ";
}

.events-requests {
  width: 100%;
  // max-width: 1280px;
  background: linear-gradient(180deg, #F5F5FD 0%, #FFFFFF 69.79%);
  height: 100%;

  .tabmenu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 548px;
    height: 3.37rem;
    background: rgb(245,245,254) 0% !important;


    .tabview-tab {
      background: rgb(245,245,254) 0% !important;
      border-radius: 0 !important;
    }
  }

  .toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: .75rem;
    margin-bottom: 1rem;
    padding-left: 0.62rem;
    padding-right: .62rem;
    justify-content: space-between;
    width: 65%;

    .search {
      width: 50%;

      .p-input-icon-left {
          width: 100%;
          display: flex;
          align-items: center;

          .pi {
              font-size: .8rem;
              margin-top: -5px;
          }

          input {
              width: 100%;
              height: 31px;
              border-radius: 15px;
              border: none;
              box-shadow: 1px 1px 30px rgb(59 130 246 / 35%);
              font-size: .8rem;
              padding-left: 2rem;
          }
      }
    }

    .p-button-label {
      font-size: 1rem;
    }

    .p-button {
      padding: 0.56rem 0.9rem;
    }

    .p-buttonset {
      white-space: nowrap;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    width: 100%;

    &.no-toolbar {
        height: calc(100vh - 200px);
    }

    .matches-feed {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 65%;
      position: relative;

      .loader{
          position: absolute;
          bottom:-8px;
          right: calc(50% - 25px);

          @keyframes p-progress-spinner-color {
            0% {
                stroke: rgb(33, 150, 243);
            }
            100% {
                stroke: rgb(33, 150, 243);
            }
        }
      }

      .loading{
          margin-top: 200px;
          @keyframes p-progress-spinner-color {
            0% {
                stroke: rgb(33, 150, 243);
            }
            100% {
                stroke: rgb(33, 150, 243);
            }
        }
      }
      .date {
        font-style: normal;
        font-weight: 600;
        font-size: .9rem;
        letter-spacing: 0.04em;
        color: var(--bluegray-800);
        padding-left: 0.62rem;

      }

      .matches-feed__item{
          // height: 67vh;
          overflow-y: scroll;
          width: 100%;
          padding: 0.8rem 0.62rem 0.62rem 0.62rem;
          box-sizing: border-box;
          margin-top: 0.5rem;

          &::-webkit-scrollbar{
              width: 0px
          }

          .match-item{
              margin-bottom: 1.2rem;
          }
      }
    }

    .side-notes {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 35%;
      height: 100%;
      box-sizing: border-box;
      padding: 0 2rem;
      border-radius: 8px;
      flex: none;
      order: 1;
      flex-grow: 0;
      position: relative;
      margin-top: 35px;

      .image {
        width: 5rem;
        height: 5rem;
        position: absolute;
        top:-40px;

        @media screen and (max-width: 1440px) {
            top: -26px;
        }
      }

      .text-group {
        display: flex;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 15px;
        padding: 74px 31px 0 37px;
        border: 1px solid var(--indigo-50);

        @media screen and (max-width: 1440px) {
            padding: 54px 21px 0 21px;
        }

        .text-group__text {
          font-style: normal;
          font-weight: 400;
          font-size: 0.87rem;
          line-height: 1.31rem;
          color: #282E38;
          padding-inline-start: 23px;
          list-style-type: side-notes;

          li {
            margin-bottom: 40px;

            a {
                color: var(--blue-500);
                text-decoration: none;
                font-weight: 600;
                transition: all .35s ease-in-out;

                &:hover {
                    opacity: .78;
                }
            }
          }
        }
      }
    }
  }
}

@import './medias.scss';

.p-tooltip .p-tooltip-text {
  font-size: 12px;
  padding: 8px;
  box-shadow: none;
}

.modal-stat {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;

  .modal-dialog {
    width: 40vw;
    min-height: 50vh;

    .modal-background {
      margin-top: -62px;
      width: 40vw;
      min-height: 50vh;
      background: #FFFFFF;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem 1rem;
      grid-gap: 1rem;

      .btn-group {
        display: flex;
        flex-direction: row;
        grid-gap: 1rem;
      }

      .title{
        display: flex;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 1.75rem;
        line-height: 2.12rem;
        color: #282E38;
        margin-bottom: 19px;
        text-align: center;
      }

      .stats {
        display: flex;
        flex-direction: row;
        grid-gap: 1rem;

        .team-stat {
          grid-gap: 1rem;
          width: 25vw;
          display: flex;
          flex-direction: column;

          .p-inputgroup {
            &span {
              width: 10vw;
            }
          }
        }
      }
    }
  }
}

.rapid-score {

    .btn-save {
        width: 208px;
        white-space: nowrap;
        margin-top: .5rem;
        font-size: .75rem;

        .p-button-label {
            font-weight: normal;
        }
    }

    .inputs {
        display: flex;
        gap: .8rem;

        &>div {
            display: flex;
            flex-direction: column;

            &:first-child {

                .p-inputwrapper {
                    flex-direction: row-reverse;

                    .p-inputnumber-button-group {

                        button:first-child {
                            border-top-left-radius: 6px;
                            border-top-right-radius: 0;
                        }

                        button:last-child {
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 0;
                        }
                    }
                }
            }

            label {
                font-size: .75rem;
                margin-bottom: .35rem;

                &[for="homeRapidScore"] {
                    text-align: right;
                }
            }

            .p-inputnumber-button-group {

                button {
                    background: var(--indigo-50);
                    border: 1px solid var(--indigo-100);
                    color: var(--indigo-500);
                }
            }

            .p-inputnumber-input {
                width: 50px;
                padding: .25rem .75rem;
                text-align: center;

                &#homeRapidScore {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                }
            }
        }
    }
}

.match-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: .7rem;
  grid-gap: 2.12rem;
  position: relative;

  width: 100%;

  background: #FFFFFF;

  box-shadow: 0 2px 12px rgba(59, 130, 246, 0.1);
  border-radius: 8px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  .refinements-group {
    position: absolute;
    right: 0.8rem;
    top: -0.8rem;
    width: max-content;
    display: flex;
    flex-direction: row;
    grid-gap: 0.2rem;

    i {
      cursor: help;
      color: var(--red-400);
      background: var(--red-50);
      padding: 4px;
      border-radius: 8px;
      border: 1px solid var(--red-100);
    }

    .p-tag.refinements {
      font-weight: normal;
      font-size: 0.68rem;
      background: var(--red-50);
      border: 1px solid var(--red-100);
      color: var(--red-400);
    }

    .p-tag.stats {
      /*position: absolute;
      right: 0.8rem;
      top: -0.8rem;*/
      font-weight: normal;
      font-size: 0.68rem;
      background: var(--blue-50);
      border: 1px solid var(--blue-100);
      color: var(--blue-400);
    }
  }

  /*.p-tag.refinements {
      position: absolute;
      right: 40px;
      top: 1px;
      font-weight: normal;
      font-size: 0.68rem;
      background: var(--red-50);
      border: 1px solid var(--red-100);
      color: var(--red-400);
  }*/

  .teams-block {
    width: 14.55vw;
    //margin-left: 1rem;

    .line {
      width: 14.55vw;
      height: 1px;
      background: #DFE7EF;
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .team {
      width: 14.25vw;
      display: flex;
      flex-direction: row;

      .name {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.87rem;
        line-height: 1.75rem;
        color: #000000;
        padding-left: 0.75rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .score {
        flex-grow: 1;

        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 0.87rem;
        line-height: 1.75rem;
        /* or 150% */

        text-align: right;

        color: #000000;
      }
    }
  }

  .meta-block {
    width: 14.55vw;
    height: 3.06rem;
    flex: none;
    order: 1;
    flex-grow: 0;

    .match-date {
        font-size: 0.68rem;
        font-weight: 600;
        color: var(--bluegray-800);
        margin-top: -7px;
        margin-bottom: 0.12rem;
    }

    .info {
      width: auto;
      height: 1.5rem;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.81rem;
      line-height: 1.31rem;
      margin-bottom: 0.25rem;
      color: var(--bluegray-800);
    }

    .tag {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0.21rem 0.35rem;
      gap: 0.21rem;
      width: max-content;
      height: 1.43rem;
      background: #F5F9FF;
      border-radius: 6px;
      flex: none;
      order: 0;
      flex-grow: 0;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 0.62rem;
      line-height: 1rem;
      color: #3B82F6;
      flex: none;
      flex-grow: 0;
    }
  }

  .status-block {
    width: 15vw;
    height: 3.06rem;
    flex: none;
    order: 2;
    flex-grow: 0;
    flex-direction: column;
    justify-content: flex-end;
    display: flex;

    .info {
      width: 15vw;
      height: 1.5rem;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.81rem;
      line-height: 1.31rem;
      margin-bottom: 0.25rem;
      color: var(--bluegray-800);
    }

    .tag {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.25rem 0.34rem;
      gap: 0.25rem;

      width: max-content;
      height: 1.43rem;

      background: #F4FCF7;
      border-radius: 0.37rem;

      flex: none;
      order: 1;
      flex-grow: 0;

      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 0.59rem;
      line-height: 1rem;

      color: #22C55E;

      &.p-tag-info {
          background: var(--orange-50);
          border: 1px solid var(--orange-100);
          color: var(--orange-500);
      }
    }
  }
}

@media screen and (max-width: 768px) {
    .live-panel {

        .club-request {
            position: relative;
            background: transparent;

            .request-info {
                margin-left: 0;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                height: unset;

                .text {
                    width: 100%;
                    text-align: center;
                    margin: 8px 0;
                    font-size: 13px;
                    line-height: 1.3;
                }

                .buttons {

                    .button {

                        .p-button {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: unset;

                            .pi {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }

            .content {
                width: 100%;
                margin-top: 10px;
                flex-direction: column;
                align-items: center;

                .editor {
                    height: unset;
                    margin-left: 0;
                    padding-top: 10px;

                    &>.text {
                        display: none;
                        margin: 0;
                    }

                    .players {
                        height: 100vh;
                        width: 100%;

                        .request-bars {

                            &>div:first-child {

                            }
                        }

                        .player {
                            flex-wrap: wrap;
                            height: unset;
                            padding: 7px;
                            border-radius: 8px;
                            grid-gap: 12px;
                            justify-content: space-between;

                            &.new-wrap {
                                margin-top: 0;
                                box-shadow: 0px 10px 10px rgba(99, 102, 241, .02);
                            }

                            &:not(.new-wrap) {
                                &:nth-child(odd) {
                                    background: var(--blue-50);
                                }
                            }

                            .new-player {
                                width: 60%;

                                .player-name {
                                    width: 100%;

                                    .p-inputtext {
                                        padding-right: 30px;
                                    }

                                    .pi {
                                        right: 10px;
                                    }
                                }
                            }

                            .bday {
                                width: 35%;
                                height: 38px;
                                font-size: 13px;
                                grid-gap: 8px;

                                .p-inputtext {
                                    width: 100% !important;
                                    font-size: 14px;
                                }

                                .icon {
                                    width: 38px;
                                    height: 38px;

                                    img {
                                        width: 22px !important;
                                        height: 22px !important;
                                        margin: 8px;
                                    }
                                }
                            }

                            .number {
                                width: 25%;
                                height: 38px;
                                font-size: 13px;
                                grid-gap: 8px;

                                .p-inputtext {
                                    width: 100% !important;
                                    font-size: 14px;
                                }

                                .icon {
                                    width: 38px;
                                    height: 38px;

                                    img {
                                        width: 24px !important;
                                        height: 24px !important;
                                    }
                                }
                            }

                            .manage-button {
                                width: calc(75% - 14px);
                                align-items: center;

                                .p-button {
                                    height: 34px;

                                    span {
                                        font-size: 13px;
                                        font-weight: normal;
                                    }
                                }
                            }

                            .player-name {
                                width: 60%;
                                height: 38px;
                                font-size: 13px;
                                grid-gap: 8px;

                                .p-inputtext {
                                    width: 100% !important;
                                    font-size: 14px;
                                }

                                .player-icon {
                                    width: 38px;
                                    height: 38px;

                                    img {
                                        width: 24px !important;
                                        height: 24px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .p-timeline {
                    margin-left: -32px;

                    .p-timeline-event {
                        min-height: 44px;
                    }
                }
            }
        }
    }
}

.score-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    width: 100%;
    margin-top: 14px;
    padding: 0 20px;
    flex-wrap: wrap;

    .qty-control {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100px;
    }

    .teams-triggers {
        width: 100%;
        padding-top: 14px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    .score-main {
        width: 152px;
        display: flex;
        justify-content: space-between;
        position: relative;

        &::before {
            content: '';
            background: #fff;
            width: 180px;
            height: 70px;
            position: absolute;
            left: -14px;
            top: -4px;
            border-radius: 6px;
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
        }

        .match-main_score_cell {
            width: 70px;
            height: 80px;
            border-radius: 6px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 48px;
            font-weight: 600;
            overflow: hidden;
            margin-top: -28px;
            background: var(--blue-500);

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 40px;
                background: rgba(0, 0, 0, .06);
            }

            &::after {
                content: '';
                background: rgba(255, 255, 255, .07);
                width: 120px;
                height: 120px;
                border-radius: 50%;
                position: absolute;
                bottom: -20px;
                left: -80px;
                z-index: 2;
            }
        }
    }
}

.auth {
    display: flex;
    height: 100vh;
    overflow: hidden;

    @media screen and (max-width: 720px) {
        flex-direction: column-reverse;
    }

    .content, .secondary {
        width: 50%;

        @media screen and (max-width: 720px) {
            width: 100%;
        }
    }

    .content {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 720px) {
            height: 70vh;
            background: linear-gradient(to bottom, #140b1f, #390882);
            margin-top: -2px;
        }

        .body {
            position: relative;
            width: 340px;
            min-height: 320px;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 4px 15px rgba(59, 130, 246, 0.1);
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 80px;
            box-sizing: border-box;

            @media screen and (max-width: 720px) {
                background: #fff;
                margin-top: -20vh;
                width: calc(100vw - 40px);
                box-shadow: 1px 1px 50px rgba(99, 102, 241, .4);
            }

            img {
                position: absolute;
                width: 60px;
                left: calc((100% - 60px)/2);
                top: -20px;
                box-shadow: 0px 4px 25px rgba(59, 130, 246, 0.1);
            }

            .title {
                margin-top: 0px;
                color: var(--bluegray-800);
                text-align: center;
            }

            .form {
                margin-top: 20px;
                width: 100%;
                padding: 0 20px;

                .p-input-icon-right {
                    width: 100%;
                    transition: all .4s ease-in-out;
                    max-height: unset;
                    overflow: unset;

                    &.collapsed-input {
                        max-height: 0;

                        input {
                            opacity: 0;
                            visibility: hidden;
                        }
                    }

                    input {
                        width: 100%;
                        transition: all .4s ease-in-out;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            .p-button {
                width: calc(100% - 40px);
                position: absolute;
                bottom: 20px;
                left: 20px;
                right: 20px;
            }
        }
    }

    .secondary {
        background: url('./abstract.jpg');
        background-repeat: no-repeat;
        background-size: cover;

        @media screen and (max-width: 720px) {
            height: 70vh;
            background-position: center;
        }
    }
}

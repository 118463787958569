.renders {
    @media screen and (min-width: 768px) {
        overflow-y: auto;
        max-height: calc(100vh - 86px);
    }

    .content {
        width: 100%;
        max-width: 600px;
        position: relative;
        margin: 0 auto;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: .75rem;
        align-items: center;
        box-sizing: border-box;

        .content-card {
            background: #fff;
            border: 1px solid var(--indigo-50);
            border-radius: 0.75rem;
            box-shadow: 1px 1px 2rem rgb(99 102 241 / 5%);
            display: flex;
            padding: 1rem;
            width: 100%;
            flex-direction: column;

            .card-title {
                font-size: .9rem;
                color: var(--indigo-900);
                font-weight: 600;
            }

            .card-section {
                margin-top: 1rem;

                .p-button-sm {
                    font-size: .75rem;
                    padding: .5rem .75rem;

                    .p-button-label {
                        font-weight: normal;
                    }
                }

                .section-title {
                    font-size: .75rem;
                    padding-bottom: .25rem;
                    border-bottom: 1px solid var(--indigo-100);
                    margin-bottom: .5rem;
                }

                .stages {

                    .nav {
                        display: flex;
                        flex-wrap: wrap;
                        gap: .6rem;

                        .stage {
                            background: var(--indigo-50);
                            border: 1px solid var(--indigo-100);
                            border-radius: .5rem;
                            color: var(--indigo-900);
                            width: 86px;
                            font-size: .75rem;
                            padding: .4rem;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: all .35s ease-in-out;

                            &.selected {
                                background: var(--indigo-400);
                                color: var(--indigo-50);
                            }
                        }
                    }

                    .btns {
                        margin-top: .6rem;
                        display: flex;
                        flex-wrap: wrap;
                        gap: .6rem;
                    }
                }
            }
        }
    }
}

.team-data {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: calc(100% - 250px);
    margin-left: 40px;

    .team-info {
        width: 323px;

        .p-inputtext {
            width: 100%;
            //display: flex;
        }
    }

    .squads-info {
        .squads-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            //max-height: 500px;
        }
        .squadPreview {
            width: calc(100% - 10px);
            padding: 10px 20px;
            margin-bottom: 20px;
            position: relative;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(59, 130, 246, 0.1);
            border-radius: 15px;
            &:first-child{
                margin-top: 10px;
            }
            .tag {
                background: #F5F9FF;
                border-radius: 6px;
                font-size: 10.5px;
                line-height: 16px;
                background: #F5F9FF;
                color: #3B82F6;
                padding: 3px 5px;
            }
            .empty {
                background: #F7F8F9;
                color: #64748B;
            }
            .tag-league {
                position: absolute;
                top: -10px;
                right: 0;
                color: #64748B;
            }
        }
    }
    .button {
        justify-content: center;
        .p-button-label {
            flex-grow: 0;
        }
    }
    .button-sub {
        margin-top: 15px;
        width: 100%;
        background-color: #F7F8F9;
        color: #556376;
        border: 1px solid #DADEE3;
        border-radius: 6px;
        text-align: center;
        justify-content: center;
        .p-button-label {
            // display: inline-block;
            // text-align: center;
            // margin: 0 auto;
            // align-self: center;
            flex-grow: 0;
        }
    }
    .fields-group.teamdata {
        padding-top: 15px;
        .emb-loader {
            .emb-current {
                margin-bottom: 0;
                //max-height: 150px;
                padding: 5px;
                img {
                    height: 150px;
                    object-fit: contain;
                }
            }
            .emb-input {
                margin-top: 5px;
                .p-button {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;

    .modal-dialog {
        width: 600px;
        min-height: 418px;

        .modal-background {
            margin-top: -62px;
            width: 600px;
            min-height: 400px;
            background: #FFFFFF;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .text {
                font-family: 'Inter', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                margin: 81px 65px 32px 66px;
                width: 469px;
                height: 54px;

                text-align: center;

                color: #37404C;
            }

            .actions {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: 0 105px 51px 72px;
                width: 423px;

                .action {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    margin-bottom: 14px;

                    .p-radiobutton .p-radiobutton-box.p-highlight {
                        border-color: #3B82F6;
                        background: #3B82F6;
                    }

                    .label {
                        font-family: 'Inter', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;

                        color: #495057;

                        margin: 0 0 0 9px;
                    }
                }
            }

            .button-group {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                margin-bottom: 20px;
                width: 430px;
                .button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 8.5px 17.5px;
                    width: 204px;
                    height: 38px;

                    background: #3B82F6;

                    border: 1px solid #3B82F6;
                    border-radius: 6px;

                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;

                    color: #FFFFFF;

                    margin-bottom: 14px;
                }
                .button-sub {

                }

                .button-escape {
                    background: rgba(100, 116, 139, 0.04);
                    border: rgba(100, 116, 139, 0.04);
                    color: #64748B;
                }
            }
        }
    }
}

.item__event{
    background: #F5F9FF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.has_focused {
    .item__event_block {
        opacity: .4;

        &.active {
            opacity: 1;
        }
    }
}

.item__event {
    margin-bottom: 15px;
}

.refinement-content {

    .author {
        font-size: 12px;
        font-weight: 600;
        color: var(--bluegray-700);
        margin-bottom: 4px;
    }

    .date {
        font-size: 11px;
        color: var(--bluegray-400);
    }

    .text {
        font-size: 14px;
        color: var(--bluegray-700);
        margin-top: 20px;
        max-width: 240px;
    }
}

.p-button-sm.refinement {
    position: absolute;
    top: -8px;
    left: -11px;
    background: var(--orange-50) !important;
    width: 32px !important;
    height: 32px !important;
    box-sizing: border-box;
    padding: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item__event_block{
    width: 100%;
    max-width: 260px;
    background: #FFFFFF;
    box-shadow: 1px 1px 10px rgba(99, 102, 241, .05);
    border-radius: 12px;
    padding: 6px 5px 7px 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: 0.3s;

    &.active{
        background: #F7F7FE;
        border-radius: 6px 6px 0 0;
    }

    &:first-child{
        margin-top: 0;
    }

    .item__event_block_time{
        display: flex;
        width: 90%;

        &:hover{
            opacity: 0.7;
            cursor: pointer;
        }

    }
    .block__time{
        display: flex;
        flex-direction: column;
        width: 40px;
        height: 55px;
        margin-right: 18px;

        .block__time_title{
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            color: #465161;
            margin-bottom: 6px;
        }

        input{
            text-align: center;
            font-size: 14px;
            width: 40px;
            height: 32px;
            padding: 10px 10px;
        }
    }
    .block__event_name{
        display: flex;
        flex-direction: column;
        justify-content: center;


        .name__executor,.name__assistant{
            display: flex;
            flex-direction: column;

            .name__executor_title,.name__assistant_title{
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: #465161;
                margin-bottom: 2px;
            }

            .name__executor_player,.name__assistant_player{
                font-style: normal;
                font-weight: 600;
                letter-spacing: .2px;
                font-size: 11px;
                line-height: 13px;
                color: var(--bluegray-800);
            }
        }

        .name__executor{
            margin-bottom: 7px;
        }

    }

    .pi-times-circle{
        color: #FFACA7;
        position:absolute;
        right: 11px;
        top:8px;
        transition: 0.2s;

        &:hover{
            cursor: pointer;
            opacity: 0.8;
        }
    }

}
.item__event_block_addition {
    padding: 13.25px 16px 10.5px 25px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-top: 1px solid #DFE7EF;
    border-radius: 6px;

    .addition__switch{
        display: flex;
        align-items: center;
        margin-bottom: 11px;

        .p-inputswitch{
            width: 30px;
            height: 16px;
        }
        .p-inputswitch .p-inputswitch-slider:before{
            width: 12px;
            height: 12px;
            left: 0px;
            top: 11px;
        }
        .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before{
                transform: translateX(15.25px);
        }

        label{
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 11px;
            color: #282E38;
            margin-left: 8px;
            }
    }

    .p-dropdown{
        width: 100%;
        height: 29px;

        .p-dropdown-label, .p-inputtext{
            padding: 8px 14px 6px;
            font-size: 11px;
        }

    }

}
.p-dropdown-panel{
    width: 174px;
}
.p-confirm-popup {
    left: 35%;
}

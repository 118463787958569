.launch-panel {
    padding-bottom: 200px;

    .panel-action {

        .p-button {

            &:first-child {
                margin-bottom: 12px;
            }
        }
    }
}

.item {
    .ticker-node {
        font-weight: 600;
        color: var(--bluegray-800);
    }

    .item-top {
        display: flex;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
        height: 106px;
        align-items: flex-end;
        padding: 12px;
        box-sizing: border-box;
        margin-top: 24px;
        position: relative;
        justify-content: space-between;
        z-index: 1;

        .p-tag.stream-control {
            position: absolute;
            top: -17px;
            right: 12px;

            &.p-tag-info {
                background: var(--gray-50);
                border: 1px solid var(--gray-100);
                color: var(--gray-600);
            }
        }

        .p-tag-secondary {
            position: absolute;
            top: -17px;
            background: var(--blue-50);
            color: var(--blue-400);
        }

        .subject-emblem {
            position: absolute;
            top: -20px;
            left: calc((100% - 64px)/2);
        }

        .team {
            display: flex;
            width: 120px;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .p-skeleton:last-child {
                margin-top: 6px;
            }

            .p-tag {
                background: var(--bluegray-50);
                color: var(--bluegray-700);
                padding: 2px 7px 0px;
                text-align: center;
                font-size: 11px;
                margin-top: 6px;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
            }
        }

        .meta {
            width: calc(100% - 240px);
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            .date {
                font-size: 10.5px;
                font-weight: 300;
                color: var(--bluegray-900);
            }

            .time {
                font-size: 12px;
                font-weight: 500;
                color: var(--bluegray-800);
                margin-top: 4px;
            }
        }
    }

    .timeline-loader {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        height: 160px;

        .p-skeleton.line {
            position: absolute;
        }
    }

    .btns-loader {
        position: absolute;
        bottom: 12px;
        left: 12px;
        right: 12px;

        .p-skeleton:last-child {
            margin-top: 8px;
        }
    }

    .item-actions {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background: #fff;
        padding: 12px;
        box-shadow: 1px 1px 22px rgba(0, 0, 0, 0.06);
        z-index: 99;

        .p-button {
            width: 100%;

            &.p-button-secondary {
                margin-top: 12px;
                background: var(--bluegray-50);
                border: none;
                color: var(--bluegray-500);
            }

            &.p-button:first-child {
                margin-top: 0 !important;
            }

            .pi {
                margin-right: 8px;
            }
        }
    }

    .item-timeline {
        margin-top: 30px;
        transition: all .5s linear;

        &.launched {
            margin-top: -46px;
            z-index: 0;
        }

        &.finished {
            margin-top: -88px;
            z-index: 0;
        }

        &.post-mode {
          margin-top: 0 !important;
        }

        .p-timeline-event-marker {
            border-color: var(--blue-500);
        }

        .marker {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 14px;
            border: 2px solid;

            &.pi-check {
                background: var(--green-500);
                color: var(--green-50);
                border-color: var(--green-200);
            }

            &.pi-cog {
                background: var(--orange-50);
                color: var(--orange-400);
                border-color: var(--orange-100);
            }
        }


        .p-tag {
            margin-top: 3px;
            background: var(--blue-500);
            min-width: 100px;

            &.disabled {
                background: var(--bluegray-100);
                color: var(--bluegray-500);
            }

            &.ready {
                background: var(--green-100);
                color: var(--green-700);
            }

            &.progress {
                background: var(--orange-100);
                color: var(--orange-700);
            }
        }
    }
}

:root {
    --common-shadow: 1px 1px 1rem rgb(99 102 241 / 7%);
    --action-grd: linear-gradient(to bottom, var(--yellow-400), var(--yellow-500));
    --action-shadow: 1px 1px 1rem rgb(238 191 49 / 35%);
}

.btn-action {
    background: var(--action-grd) !important;
    color: var(--gray-800) !important;
    border: none !important;
    box-shadow: var(--action-shadow);
}

.btn-secondary {
    background: var(--gray-100) !important;
    border: none !important;
    color: var(--gray-500) !important;
}

.meta-block {
    display: flex;
    flex-direction: column;
    padding-top: .35rem;
    gap: .25rem;

    &>div {
        display: flex;
        font-size: .8rem;
        align-items: center;
        color: var(--gray-700);

        .match-shortcut {
            font-size: .7rem;
            display: flex;
            flex-direction: column;
            gap: .1rem;
            width: 100%;

            &>div {
                white-space: nowrap;
                overflow: hidden;
            }

            .p-badge {
                width: 100%;
                display: block;
                margin-top: .25rem;
            }
        }

        .p-badge {
            margin-right: .25rem;
            background: var(--action-grd);
            color: var(--gray-900);
            box-shadow: var(--action-shadow);
            border-radius: .35rem;
            height: 1rem;
            line-height: 1rem;
            min-width: 1.2rem;
        }
    }
}

.coming-soon {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 5rem;

    &>div {
        text-align: center;

        &:first-child {
            padding-top: 5rem;
            padding-bottom: 3rem;
            font-size: 1.2rem;
            opacity: 0.8;
        }

        &.desc {
            opacity: 0.8;
            font-size: 0.9rem;
            padding: 0 2rem;
        }
    }

    img {
        width: 64px;
        margin-bottom: 3rem;
    }

    .p-button {
        margin-bottom: -2.5rem;
        width: 100%;
        position: absolute;
        bottom: 0rem;
    }
}

.club-layout {
    padding: 0 .75rem;
    box-sizing: border-box;
    height: calc(100vh - 60px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    color: var(--bluegray-900);

    .nav-tile {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 0;

        .nav-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: nowrap;
            gap: 1rem;

            &._1 {
                height: 200px;
            }

            &._2 {
                height: 64px;
            }

            &._3 {
                height: 100px;
            }

            .nav-item {
                width: 100%;
                height: 100%;
                background: #fff;
                border-radius: .5rem;
                box-shadow: var(--common-shadow);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                box-sizing: border-box;
                padding: .75rem;
                position: relative;

                .icon {
                    width: 64px;
                    position: absolute;
                    top: -4px;
                    right: -4px;
                    -webkit-mask-image: linear-gradient(to top, transparent 10%, #fff);
                }

                .label {
                    font-size: .8rem;
                    font-weight: 500;
                }
            }
        }
    }
}

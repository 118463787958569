.match__modal{
    width: 66vw;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 32px 0px 0px;
    box-sizing: border-box;
    position: relative;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;

    .custom-tabs {
        padding: 10px 0;
        display: flex;
        justify-content: center;

        .custom-tabs_btn {
            padding: 11px 16px;
            background: var(--indigo-50);
            // border: 2px solid var(--indigo-300);
            color: var(--indigo-500);
            border-radius: 20px;
            font-size: 13px;
            display: flex;
            align-items: center;
            margin: 0px 10px;
            font-weight: 500;
            transition: all 0.4s ease-in-out;
            box-shadow: 1px 1px 10px rgba(99, 102, 241, .1);

            i {
                margin-right: 6px;
                font-size: 13px;
            }

            &:hover {
                cursor: pointer;
                opacity: .92;
                box-shadow: none;
            }

            &.active {
                background: var(--indigo-500);
                color: var(--indigo-50);
                border-color: var(--indigo-600);
                box-shadow: 1px 1px 30px rgba(99, 102, 241, .4);
            }
        }
    }

    .button__close{
        position: absolute;
        top:20px;
        right: 0px;
    }

    .button__save {
        position: absolute;
        top:20px;
        right: 80px;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;

        &:hover {
            cursor: pointer;
            opacity: .65;
        }

        .button-text {
            text-transform: uppercase;
            color: var(--green-500);
            padding-left: 14px;
        }
    }

    .loader{
        margin: auto;
    }

    .match__modal_title{
        display: flex;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: #282E38;
        margin-bottom: 19px;
    }

    .match__modal_group-btn{
        display: flex;
        justify-content: center;
        margin-bottom: 6px;

        .p-button.p-button-secondary.p-button-text{
            background: rgba(100, 116, 139, 0.04);
            border-color: transparent;
            color: #64748B;
            margin-right: 15px;
        }

        .p-button.p-button-secondary.p-button-text:enabled:hover{
            background-color: transparent;
            color: #64748B;
            border-color: transparent;
        }
    }

    .p-tabmenu{
        display: flex;
        justify-content: center;
         .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus{
            box-shadow: unset;
            outline: unset;
            outline-offset: unset
        }
    }
}

.p-dialog .p-dialog-content:last-of-type{
    border-radius: 12px;
    padding-bottom: 20px;
    overflow-y: hidden;
}

@import './components/RosterController/style.scss';
@import './components/Information/style.scss';
@import './components/Events/style.scss';

.theme-conf {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    height: calc(100vh - 86px);
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 40px;
    padding-right: 10px;

    .p-inputgroup {
        margin: 0;

        &>* {
            font-size: .75rem;
            padding: .25rem .5rem;
        }

        .p-inputgroup-addon:first-child {
            width: 40%;
        }

        .p-inputtext {
            width: 40%;
        }

        .preview {
            &:hover {
                cursor: pointer;
            }
        }

        .p-button {
            background: var(--bluegray-100);
            color: var(--bluegray-400);
            border-color: var(--bluegray-200);
        }
    }
}

.colors-matrix {
    width: 360px;
    font-size: .9rem;

    .row {
        width: 100%;
        display: flex;
        align-items: center;
        height: 23px;

        &.head {
            .idx {
                font-size: .7rem;
                font-weight: 600;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .color {
            width: 80px;
        }

        .idx {
            width: 28px;
            height: 23px;
            text-align: center;
            opacity: 1;
            transition: all .35s ease-in-out;

            &:hover {
                opacity: .65;
                cursor: pointer;
            }
        }
    }
}

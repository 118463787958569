.custom-input {
    width: 100%;
    height: 2.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1.06rem;
    margin-top: 1.12rem;
    background: #F5F9FF;
    box-shadow: 0 4px 10px rgb(191 219 254 / 30%);
    border-radius: 0.93rem;

    .icon {
        justify-content: center;
        width: 2.75rem;
        height: 2.75rem;
        background: #D0E1FD;
        border-radius: 0.93rem;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.68rem;
        display: flex;
        align-items: center;
        color: #8183F4;
    }

    .p-inputtext {
        border: none;
        background: none;
        padding-left: 0;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .p-dropdown {
        background: transparent;
        border: none;
        width: calc(100% - 3.81rem);

        &.p-inputwrapper-focus {
            outline: none;
            box-shadow: none;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

.sponsors {
    //padding: 20px 0 0 17px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;

    .teamadd {
        background: var(--indigo-50);
        color: var(--indigo-500);
        border-color: var(--indigo-100);
        border-radius: 20px;
    }

    .sponsors-content {
        display: flex;
        flex-direction: column;
        width: 650px;
        min-height: 500px;

        .sponsors-list{
            display: flex;
            flex-direction: row;
            //align-items: center;
            padding-top: 10px;

            .team-btn {
                width: 100%;
                margin-bottom: 12px;
                position: relative;

                .p-button {
                    justify-content: center;
                    width: 100%;
                    box-shadow: 1px 1px 8px rgba(59, 130, 246, .1);
                    border-radius: 20px;
                }

                .p-tag {
                    padding: 2px 7px;
                    border-radius: 10px;
                    font-size: 10px;
                    font-weight: normal;
                    position: absolute;
                    right: 10px;
                    top: -10px;
                    background: var(--blue-50);
                    color: var(--blue-600);
                }
            }
        }

        .team {
            width: calc(100% - 10px);
            height: 100px;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(59, 130, 246, 0.1);
            border-radius: 15px;
            margin-bottom: 12px;
            margin-right: 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 28px 17px 28px 17px;
            cursor: pointer;

            .text-area {
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .text {
                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;

                    color: #37404C;
                }

                .tag {
                    width: auto;
                    margin-top: 3px;
                    background: #F5F9FF;
                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10.5px;
                    line-height: 16px;

                    color: #3B82F6;
                }

                .tag-grey {
                    width: auto;
                    margin-top: 3px;
                    background: #F7F8F9;;
                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10.5px;
                    line-height: 16px;

                    color: #64748B;;
                }
            }
        }
        .selected {
            background: #DADAFC;
        }
    }
}

.side-notes {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 393px;
  height: 100%;
  border-radius: 0.5rem;
  flex: none;
  order: 1;
  flex-grow: 0;
  position: relative;
  color: var(--bluegray-800);

  .primary-action {
      width: calc(100% - 40px);
      margin-left: 1.25rem;
      margin-right: 1.25rem;

      .p-button {
          width: 100%;
      }
  }

  .image {
    width: 5rem;
    height: 5rem;
    position: absolute;
    top:-1.75rem;

    img {
        width: 100%;
    }
  }

  .text-group {
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, rgba(255,255,255,0.8) 50%, transparent);
    border-radius: 0.9375rem;
    padding: 74px 31px 0 37px ;

    .text-group__text {
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.315rem;
      color: var(--bluegray-800);
      padding-inline-start: 23px;

      li {
        margin-bottom: 30px;
      }
    }
  }
}

.tournament-settings {
  margin-top: 20px;
  //justify-content: space-between;
  flex-direction: column;

    .text {
        margin-left: 14px;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 17.5px;
        line-height: 21px;

        color: #465161;
    }

  .title {
    display: flex;
    flex-direction: column;
    .text {
      font-weight: 600;
      color: #495057;
      margin-left: 0px;
      margin-bottom: 14px;
    }

    .p-inputtext {
      padding: 10.5px;

      width: 390px;
      height: 42px;
    }
  }

  .p-fluid {
      max-width: 780px;
  }

  .switch-frame {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    .switch-frame2 {
      display: flex;
      flex-direction: row;
    }

    .switch-obj {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 74px 20px 0;
      width: 334px;

      .p-inputnumber {
        margin-left: 20px;
        margin-right: 10px;
        .p-inputnumber-input {
          width: 82px;
          height: 42px;

          background: #FFFFFF;

          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 17.5px;
          line-height: 21px;

          text-align: center;

          color: #465161;
        }
      }

      .p-inputswitch-checked {
        .p-inputswitch-slider {
          background: #3B82F6 !important;
        }
      }
    }

    .withoutSwitch {
        margin: 0 60px 20px 0;
        width: 334px;

        .p-inputnumber {
            margin-left: 20px;
        }
        .text {
            margin-left: 0;
            white-space: nowrap;
        }

    }
  }
    .schemaBtns-container {
        display: flex;
        flex-direction: column;
        .schemaBtns-title {
            margin-bottom: 0px;
            margin-left: 0;
        }
        .schemaBtns{
            border-radius: 8px;
            box-sizing: border-box;
            padding: 0 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 1rem;
            margin-bottom: 10px;
            color: #465161;
            font-size: 14px;

            &.grid_two {
                grid-template-rows: 1fr 1fr;
            }
            &.grid_three {
                grid-template-rows: 1fr 1fr 1fr;
            }
            &.grid_four {
                grid-template-rows: 1fr 1fr 1fr 1fr;
            }
        }


        .field-radiobutton{
            // margin-bottom: 1rem;
            display: flex;
            align-items: center;

            .p-radiobutton, .p-component, .p-radiobutton-checked{
                display: inline-flex;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                vertical-align: bottom;

                .p-hidden-accessible{
                    border: 0;
                    clip: rect(0 0 0 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;

                    input{
                        transform: scale(0);
                    }
                }

                .p-radiobutton-box, .p-highlight{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .p-radiobutton-icon{
                        transform: translateZ(0) scale(1);
                        visibility: visible;
                        border-radius: 50%;
                    }
                }
            }

            label{
                margin-left: 9px;
            }
        }
    }
    .label.text.nm {
      font-weight: 400;
      line-height: 21px;
      color: #465161;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
}

.geoSelect {
    //width: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    //margin-bottom: 10px;
    .p-dropdown {
        width: 100%;
        margin-top: 10px;
    }
}

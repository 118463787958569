.content {
  width: 1280px;

  .schedule-staff-bars {
      margin: 0 -0.94rem;
      width: calc(100% + 30px);
      height: calc(100vh - 145px) !important;
      display: flex;
      max-height: unset !important;

      &>div:first-child {
          padding: 0 0.94rem;
          width: 100%;
          height: calc(100vh - 160px) !important;
          max-height: unset !important;
          overflow-x: hidden !important;

          &::-webkit-scrollbar {
              display: none;
          }
      }
  }

  .staff-controls {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .btn-group {
          display: flex;
          gap: .5rem;
          margin-bottom: 1.5rem;

          .p-button {
              padding: 0.4rem 0.75rem;
              background: #fff;
              border: none;
              font-size: .85rem;
              white-space: nowrap;
              overflow: hidden;

              &:hover {
                  border: none !important;
              }

              &:not(.p-button-outlined) {
                  background: var(--blue-500);
              }

              span {
                  font-weight: normal;
              }
          }
      }

      .staff-list {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0.75rem;
          width: 100%;
          box-sizing: border-box;

          .person-item {
              background: #fff;
              box-shadow: 1px 1px 20px rgb(59 130 246 / 10%);
              border-radius: 0.75rem;
              padding: 0.75rem 0.75rem 0.375rem;
              display: flex;
              position: relative;

              .person-info {
                  padding-left: 0.87rem;

                  .name {
                      font-size: 0.81rem;
                      line-height: 0.94rem;
                      color: var(--bluegray-800);
                  }
              }

              .p-tag {
                  background: var(--blue-50);
                  color: var(--blue-500);
                  font-weight: normal;
                  margin-top: 0.56rem;
                  font-size: 0.69rem;
              }

              img {
                  width: 3.75rem;
              }
          }
      }
  }

  .schedule-list {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content:  flex-start;
    padding: 0;
    height: calc(100vh - 145px) !important;
    width: 44%;
    flex-wrap: wrap;

    //overflow: hidden;

    margin: 0px 0 0px 0px;

    .splide__track {
      max-width: 1280px;
    }

    .splide__arrows {
      width: 1420px;
      position: absolute;
      top: 50%;
      left: -70px;

      .splide__arrow {
        background: none;
        font-size: 21px;

        svg {
          fill: #609AF8;
        }
      }


    }

    .p-carousel-items-container{width: 87%}

    .p-carousel-prev,.p-carousel-next{color: #609AF8;}

    .p-carousel-indicators {visibility:hidden;}

    .p-items-hidden .p-carousel-item{visibility:visible}

    .tour-shadow-container {
      padding: 0 10px 20px 10px;
      position: relative;

      .render-btn {
          font-size: .7rem;
          padding: .35rem .7rem;
          position: absolute;
          top: -13px;
          right: 50px;
          background: var(--indigo-50);
          border: 1px solid var(--indigo-100);
          color: var(--indigo-400);
      }
    }

    .tour {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      height: auto;
      width: 100%;

      background: #FFFFFF;
      box-shadow: 0 4px 20px rgba(59, 130, 246, 0.1);
      border-radius: 12px;

      .dnd-tip {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 0 1rem 0.5rem;
          color: var(--bluegray-300);
          font-size: 0.68rem;
          font-weight: 600;
      }

      .tour-number {
        height: 2.25rem;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.81rem;
        line-height: 2.25rem;

        text-align: center;

        display: flex;
        justify-content: center;

        color: #282E38;

        .number {
        }

        .openlist {
          justify-self: flex-end;
          align-self: flex-end;
          cursor: pointer;
          margin-left: 10px;
        }
      }

      .match {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;

        .refinements-group {
          position: absolute;
          right: 2.5rem;
          width: max-content;
          display: flex;
          flex-direction: row;
          grid-gap: 0.18rem;

          i {
            cursor: help;
            color: var(--red-400);
            background: var(--red-50);
            padding: 0.2rem;
            border-radius: 0.4rem;
            border: 1px solid var(--red-100);
            font-size: 0.7rem;
          }
        }

        .sheet-trigger {
            position: absolute;
            font-size: .5rem;
            padding: .2rem;
            width: 1.5rem;
            left: 2.5rem;

            .p-button-label {
                font-weight: normal;
            }

            .p-button-icon {
                width: 1rem;
            }
        }

        &.dragged {
            flex-direction: column;
            width: 200px;

            &>div {
                text-align: center;
            }
        }

        width: 29vw;
        height: 2.75rem;

        background: #F6F9FC;

        .text-1 {
          width: calc((100% - 100px)/2);
          height: 2.75rem;
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1rem;
          display: flex;
          align-items: center;
          justify-content: end;
          text-align: end;
          color: var(--bluegray-700);
        }

        .text-2 {
          width: 100px;
          height: 2.75rem;
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          flex-wrap: wrap;

          .p-tag {
            background: var(--bluegray-50);
            color: var(--bluegray-700);
          }

          a {
            display: flex;
            flex-direction: column;

            .match-time {
              font-size: 0.75rem;
              font-weight: 600;
              width: 100%;
              color: var(--bluegray-800);
            }

            .match-date {
              font-size: 0.625rem;
              width: 100%;
              color: var(--bluegray-500);
            }
          }

          img {
            width: 1.87rem;
            animation: ball-animation 4s linear infinite;
          }

          &:active {
            animation: ball-animation 4s linear infinite;
          }

          @keyframes ball-animation {
            from {
              transform: rotate(-360deg);
            }
          }
        }

        .text-3 {
          width: calc((100% - 100px)/2);
          height: 2.75rem;
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1rem;
          display: flex;
          align-items: center;
          color: var(--bluegray-700);
        }
      }
    }
  }

  .slots-wrap {
      width: 40vw !important;
      margin-left: 1%;
      background: #fff;
      padding-top: 0px;
      border-radius: 12px;
      height: calc(100vh - 145px) !important;
      max-height: unset !important;

      &>div:first-child {
          height: calc(100vh - 145px) !important;
          max-height: unset !important;
          overflow-x: hidden !important;
      }
  }

  .slots {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 2rem 1.25rem;
    margin-bottom: 30px;
    margin-top: 20px;

    filter: drop-shadow(0px 4px 10px rgba(223, 231, 239, 0.25));

    flex-wrap: wrap;

    padding-bottom: 6%;

    .slot {
      list-style: none;
      width: 12vw;
      min-height: 14vh;
      background: #F7F8F9;
      border: 1px solid #F2F2F2;
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .tag {
        display: flex;
        margin-top: -8px;
        flex-direction: row;
        align-items: flex-start;
        padding: 3.5px 5.6px;
        white-space: nowrap;

        width: max-content;
        height: 1.44rem;

        background: #DADEE3;
        border-radius: 6px;

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 0.66rem;
        line-height: 1rem;

        color: #556376;
        z-index: 1;
      }

      .slot-match {
        width: 12vw;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-direction: column;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 0.66rem;
        line-height: 0.81rem;
        color: #37404C;
        padding-top: 10px;
        padding-bottom: 10px;

        a {
            margin-bottom: 6px;
        }

        .staff {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: var(--blue-600);
            flex-wrap: wrap;
            padding: 0.25rem 0.375rem 0;
            grid-gap: 0.25rem;

            .p-chip {
                font-size: 0.53rem;
                white-space: nowrap;
                padding: 2px 2px 2px 5px;
                line-height: 1.25rem;

                .p-chip-text {
                    margin: 0;
                }

                .p-chip-remove-icon {
                    line-height: 1;
                    margin-left: 2px;
                }
            }
        }

        .pi-bolt {
            color: var(--yellow-400);
        }

        .home-team {
          width: 5rem;
          display: flex;
          align-items: center;
          justify-content: end;
          text-align: end;
          padding-right: 5px;
          color: var(--bluegray-800);
        }

        .away-team {
          width: 5rem;
          display: flex;
          align-items: center;
          padding-left: 5px;
          color: var(--bluegray-800);
          margin-right: -8px;
        }

        .delete-match {
          position: absolute;
          right: 0.37rem;
          top: -0.625rem;
          cursor: pointer;
        }
      }
      .tag-group {
        display: flex;
        flex-direction: row;

        grid-gap: 3.8px;

        justify-self: end;
        margin-bottom: -8px;

        .tag1 {
          display: flex;
          flex-direction: row;
          align-items: flex-start;


          width: 82.2px;
          height: 23px;

          background: #DADEE3;
          border-radius: 6px;

          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 10.5px;
          line-height: 16px;

          color: #556376;
        }

        .tag2 {
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          height: 23px;
          background: var(--blue-50);
          color: var(--blue-600);
          border: 1px solid var(--blue-100);
          border-radius: 6px;

          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 10.5px;
          line-height: 16px;

          &.available {
              background: var(--bluegray-50);
              color: var(--bluegray-500);
              border: 1px solid var(--bluegray-100);
          }
        }

        .p-tag {
          height: 1.70rem;
        }

        .p-tag-value {
          font-size: 0.75rem;
        }
      }
      }

  }
}


@import './medias.scss';
